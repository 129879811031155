// @font-face {
//     font-family: 'SharikSans';
//     src: url('../fonts/sharik/shariksansregular/shariksansregular.eot');
//     src: url('../fonts/sharik/shariksansregular/shariksansregular.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/sharik/shariksansregular/shariksansregular.woff') format('woff'),
//          url('../fonts/sharik/shariksansregular/shariksansregular.ttf') format('truetype'),
//          url('../fonts/sharik/shariksansregular/shariksansregular.svg#shariksansregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'SharikSans';
//     src: url('../fonts/sharik/shariksansregularitalic/shariksansregularitalic.eot');
//     src: url('../fonts/sharik/shariksansregularitalic/shariksansregularitalic.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/sharik/shariksansregularitalic/shariksansregularitalic.woff') format('woff'),
//          url('../fonts/sharik/shariksansregularitalic/shariksansregularitalic.ttf') format('truetype'),
//          url('../fonts/sharik/shariksansregularitalic/shariksansregularitalic.svg#shariksansregularitalic') format('svg');
//     font-weight: normal;
//     font-style: italic;
// }
// @font-face {
//     font-family: 'SharikSans';
//     src: url('../fonts/sharik/shariksansbold/shariksansbold.eot');
//     src: url('../fonts/sharik/shariksansbold/shariksansbold.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/sharik/shariksansbold/shariksansbold.woff') format('woff'),
//          url('../fonts/sharik/shariksansbold/shariksansbold.ttf') format('truetype'),
//          url('../fonts/sharik/shariksansbold/shariksansbold.svg#shariksansbold') format('svg');
//     font-weight: bold;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'SharikSans';
//     src: url('../fonts/sharik/shariksansbolditalic/shariksansbolditalic.eot');
//     src: url('../fonts/sharik/shariksansbolditalic/shariksansbolditalic.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/sharik/shariksansbolditalic/shariksansbolditalic.woff') format('woff'),
//          url('../fonts/sharik/shariksansbolditalic/shariksansbolditalic.ttf') format('truetype'),
//          url('../fonts/sharik/shariksansbolditalic/shariksansbolditalic.svg#shariksansbolditalic') format('svg');
//     font-weight: bold;
//     font-style: italic;
// }




@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-Hairline.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-Hairline.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-Hairline.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Hairline.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Hairline.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-HairlineItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-HairlineItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-HairlineItalic.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-HairlineItalic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-HairlineItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-Thin.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-Thin.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Thin.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-ThinItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-ThinItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-ThinItalic.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-ThinItalic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-ThinItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 200;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-Light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-Light.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Light.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-LightItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-LightItalic.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-LightItalic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-Regular.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-Italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-Italic.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-Medium.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-Medium.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Medium.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-MediumItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-MediumItalic.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-MediumItalic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-MediumItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-Semibold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-Semibold.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Semibold.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Semibold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-SemiboldItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-SemiboldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-SemiboldItalic.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-SemiboldItalic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-SemiboldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-Bold.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Bold.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-BoldItalic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-Heavy.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-Heavy.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Heavy.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Heavy.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-HeavyItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-HeavyItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-HeavyItalic.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-HeavyItalic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-HeavyItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 800;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-Black.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-Black.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Black.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/LatoLatin-BlackItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/lato/LatoLatin-BlackItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato/LatoLatin-BlackItalic.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-BlackItalic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato/LatoLatin-BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
    text-rendering: optimizeLegibility;
}
