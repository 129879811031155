@charset 'utf-8';

@import 'fonts';
@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
@include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

body {
  background: #fefefe url("../img/back.png") center fixed;
  background-size: cover;

  & > .row {
    background: rgba(254, 254, 254, .7);
  }
}

#site-header {
  @include grid-column-row;
  padding-top: 2em;
  padding-bottom: 2em;
  background: url('../img/noten.png') no-repeat 50% 50%;
  h1 {
    img {
      float: left;
      width: 60%;
      height: auto;
      margin: 0 1rem 0 0;
    }
  }
  img {
    height: 3.5em;
    margin-bottom: -2em;
    @include breakpoint(medium) {
      font-size: 1.8em;
    }
    @include breakpoint(large) {
      font-size: 2em;
    }
  }
}
#site-nav-sticky-container,
#page-nav-sticky-container {
  margin-bottom: 1rem;
}
#site-nav {
  width: 100%;
  z-index: 10;

  .top-bar-left {
    transition: opacity 2s;
    .menu-text {
      a {
        color: $white;
        display: inline;
        padding: 0;
      }
    }
  }

  &:not(.is-stuck) {
    .top-bar-left {
      // display: none;
      position: absolute;
      top: -300px;
      opacity: 0;
    }
    .container {
      @include grid-column-row;
    }
  }

  .menu-text {
    a {
      color: $white;
    }
  }

  a.active {
    background: $primary-color;
    color: $white;
  }
  .is-dropdown-submenu-parent.is-down-arrow > a.active::after {
    border-color: $white transparent transparent;
  }
  .is-dropdown-submenu-parent.is-left-arrow > a.active::after {
    border-color: transparent $white transparent transparent;
  }
  .is-drilldown-submenu-parent > a.active::after,
  .is-dropdown-submenu-parent.is-right-arrow > a.active::after {
    border-color: transparent transparent transparent $white;
  }
}
.top-bar a > img {
  height: 2em;
  margin: -1em 0;
  margin-right: -4em !important;
}
#site-footer {
  margin: 7em 0 0 0;
  padding: 1em;
  text-align: center;
  background: $topbar-background;

  p {
    margin: 0;
  }
}

.top-bar, #site-footer{
   a {
     color: inherit;
   }
 }


#page-nav {
  z-index: 9;
  a {
    background: $white;
    margin-right: 1rem;
    &.active {
      background: $primary-color;
      color: $white;
    }
  }

  .stuck-only {
    opacity: 0;
    font-size: 0;
    transition: opacity .5s, font-size .5s;
  }

  &.is-stuck {
    .stuck-only {
      opacity: 1;
      font-size: inherit;
    }
  }
}

// Give in page anchors some space.
section[id] {
	padding-top: 5rem;
	margin-top: -5rem;

	&:last-child {
		margin-bottom: 30rem;
	}
}

// Contents Styles

.pictured-text {
  @include grid-row-nest;
  header {
    @include grid-column;
  }
  .picture {
    @include grid-column;
    text-align: center;
    img {
      @extend .thumbnail;
      width: 100%;
      max-width: 256px;
    }
  }
  .text {
    @include grid-column;
  }
  @include breakpoint(medium) {
    .picture {
      @include grid-column(3);
    }
    .text {
      @include grid-column(9);
    }

    &.other {
      .picture {
        @include grid-col-pos(9);
      }
      .text {
        @include grid-col-pos(-3);
      }
    }
  }
  @include breakpoint(large) {
    .picture {
      @include grid-column(2);
    }
    .text {
      @include grid-column(10);
    }

    &.other {
      .picture {
        @include grid-col-pos(10);
      }
      .text {
        @include grid-col-pos(-2);
      }
    }
  }
}

.quote-list {
  @include grid-row-nest;
  blockquote {
    @include grid-column;
    @include breakpoint(medium) {
      @include grid-column(6);
    }
    @include breakpoint(large) {
      @include grid-column(4);
    }
    margin-bottom: 2rem;
    color: inherit;
  }
}

.seminar {
  @include grid-row-nest;
  h3 {
    @include grid-column;
  }
  p.lead, dl {
    @include grid-column;
    @include breakpoint(medium) {
      @include grid-column(6);
    }
  }
  dd+dt {
    margin-top: 1rem;
  }
}

.image-caption {
  text-align: center;
  margin-top: -.5em;
}
